import React, { useState } from 'react';
import Icon_div from './Icon_div';
import { useNavigate } from 'react-router-dom';
import svg from '../images/SLA_logo_Blue.svg';
import Mtm_offcanvas from './Mtm_offcanvas';

const Navbar = () => {
  const [state, setstate] = useState(false);

  const navigate = useNavigate();

  return (
    <div
      className="py-2 poppins "
      style={{ position: 'relative', zIndex: 5 }}>
      <img src={require('../assest/navBarbg.png')}
        className='absolute top-30 -translate-y-1/2 z-0 ' alt="NavbarScroll" />
      <div className=' absolute top-0 navbarclrgrd flex opacity-60 overflow-hidden w-full h-[100px] -translate-y-1/2' >
       
      </div>
      <div className="row container relative m-0 mx-auto text-lg text-slate-800 justify-between  align-items-center">
        <div className="col-5 col-lg-2">
          <img
            onClick={() => navigate('/')}
            src={svg}
            alt="Image logo"
            className=' cursor-pointer '
            width={140} />
        </div>

        <div className="d-none col-3 fw-medium d-lg-flex
         align-items-center justify-content-between ms-auto">
          <span className="  d-flex align-items-center">
            <a
              href="https://portfolio.meridatechminds.com"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-lg text-slate-800 ">
              Portfolio
            </a>
          </span>

          <button
            onClick={() => navigate('/blogs')}
            className=" d-flex text-lg text-slate-800 align-items-center">
            Blogs
          </button>
          <button
            onClick={() => navigate('/contact-us')}
            className=" d-flex text-lg text-slate-800 align-items-center">
            Contact Us
          </button>
          {/* Button for the modal */}
          {/* <img
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setstate(true);
            }}
            src={require('../images/Group 1000002139.png')}
            width={46}
            alt=""
          /> */}
        </div>
        {/* Icon */}
        <div className="  flex justify-end col-7 ms-auto text-end d-lg-none">
          <svg
            style={{ cursor: 'pointer' }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasScrolling"
            aria-controls="offcanvasScrolling"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-text-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </div>
      </div>
      <Icon_div
        state={state}
        setstate={setstate}
      />
      <Mtm_offcanvas />
    </div>
  );
};

export default Navbar;
