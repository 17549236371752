import React from 'react'

const HomeBanner = () => {
    return (
        <div>
            <main className=' flex min-h-[50vh] sm:min-h-[85vh] ' >
                <section className='m-auto relative ' >
                    <div className='relative ' >
                        <h5 className='text-9xl sm:text-[17rem] lg:text-[22rem] 
                        txtgrd pt-32 px-10 
                        xl:text-[26rem] anesha ' >Merida </h5>
                        <h6 className='anesha   absolute
                         text-2xl mx-auto tracking-[0.4rem]
                         lg:text-8xl text-nowrap 
                         bottom-[7%]
                         sm:bottom-[10%] left-[40%] text-slate-700 ' >
                            tech minds
                        </h6>
                        {/* Right */}
                        <hr className='opacity-100 border-4 rounded absolute 
                        w-[3rem] bottom-[18%] right-[10%]
                        sm:bottom-[25%] sm:right-[7%] sm:w-[14rem]
                        m-0 p-0 ' />
                        {/* Left */}
                        <hr className='opacity-100 border-4 rounded absolute  
                        bottom-[18%] left-[20%] w-[4rem]
                        sm:bottom-[27%] sm:left-[17%] sm:w-[12rem]
                        m-0 p-0 ' />
                    </div>
                    <p className='text-center text-sm fw-medium sm:text-xl text-slate-700 poppins w-[70%] mx-auto ' >
                        Based on above calculation and no of storage days you want
                        that can be multiplied and arrive the total water Requirement
                    </p>
                </section>

            </main>
        </div>
    )
}

export default HomeBanner