import React, { useEffect, useRef, useState } from 'react'
import ServiceCards from '../MiniCards/ServiceCards'
import useInView from '../CustomHooks/useInView'

const HomeCard = () => {
    let [ref, isInView] = useInView()
    let [ref2, isInView2] = useInView()
    let [ref3, isInView3] = useInView()
    let [ref4, isInView4] = useInView()
    let [ref5, isInView5] = useInView()
    let [ref6, isInView6] = useInView()
    let [ref7, isInView7] = useInView()
    let [ref8, isInView8] = useInView()

    let cardContent = [
        {
            img: '../assest/softwareConsultingCard.png',
            content: `Customized Software Services That Meet Your Business Needs`,
            heading: 'Software',
            sptheading: 'Consulting',
            splfont: ' order-2 strong text-[#E0FD10] ',
            path: 'service/software-consulting',
            ref: ref,
            isshow: isInView
        },
        {
            img: '../assest/mobileAppDevelopment.png',
            content: `Create Engaging Apps That Enhance User experiences Easily`,
            heading: 'Mobile app development',
            path: 'service/best-mobile-app-development-company-bengaluru',
            ref: ref2,
            isshow: isInView2
        },

        {
            img: '../assest/digitalMarketing.png',
            content: `Reach more People with Our Digital Marketing Expertise`,
            heading: 'Digital marketing',
            path: 'service/digital-marketing',
            ref: ref3,
            isshow: isInView3
        },
        {
            img: '../assest/websiteDevelopment.png',
            content: `Web Solutions That Make Your Business Accessible Online`,
            heading: 'Website ',
            sptheading: 'Development ',
            splfont: ' order-2 megira text-[#F19603]',
            path: 'service/website-development-company',
            ref: ref4,
            isshow: isInView4
        },
        {
            img: '../assest/leadgenerationCard.png',
            content: `Bringing in Quality Leads That Turn into Loyal Customers`,
            heading: 'Lead Generation',
            path: 'service/lead-generation-services',
            ref: ref5,
            isshow: isInView5
        },
        {
            img: '../assest/graphicDesigning.png',
            content: `Delivering Designs That Speak Your Brand’s Language`,
            heading: 'Graphic',
            sptheading: 'Designing',
            splfont: ' order-2 strong text-[#DAAF03] ',
            path: 'service/graphic-design-services',
            ref: ref6,
            isshow: isInView6
        },
        {
            img: '../assest/logodesigning.png',
            content: `Customized Software Services That Meet Your Business Needs`,
            heading: 'Designing',
            sptheading: 'Logo ',
            splfont: ' megira text-[#F19603] order-0 ',
            path: 'service/logo-designing-company',
            ref: ref7,
            isshow: isInView7
        },
        {
            img: '../assest/brandBuilding.png',
            content: `Delivering Designs That Speak Your Brand’s Language`,
            heading: 'Brand Building',
            path: 'service/brand-building',
            ref: ref8,
            isshow: isInView8
        },

    ]

    return (
        <div>
            <main className={`
            duration-1000 row  mx-auto container-fluid px-2 py-5 `}>
                {
                    cardContent.map((obj, index) => (
                        <div ref={obj.ref} className={` ${obj.isshow ? 'opacity-100' : ' opacity-0 translate-y-20'}
                        ${index % 2 == 0 ? "" : "delay-300 "} duration-1000 transition-all my-[1.6rem] col-md-6 `} >
                            <ServiceCards data={obj} />
                        </div>
                    ))
                }
            </main>

        </div>
    )
}

export default HomeCard