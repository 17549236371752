import React, { useState } from 'react'
import Navbar from '../Landingpages/Navbar';
import HomeBanner from '../Landingpages/HomeBanner';
import HomeCard from '../Landingpages/HomeCard';
import HowWeWorks from '../Landingpages/HowWeWorks';
import WhyChooseUs from '../Landingpages/WhyChooseUs';
import Scrolltotop from '../Scrolltotop';
import Backtotop from '../Backtotop';
import Blogs from '../Landingpages/Blogs';

const HomePageNew = () => {
    const [state, setstate] = useState(false);

    return (
        <div>
            <Navbar />
            <HomeBanner />
            <HomeCard />
            <HowWeWorks />
            <WhyChooseUs />
            <Blogs />

        </div>
    )
}

export default HomePageNew
