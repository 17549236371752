import React from 'react'
import { useNavigate } from 'react-router-dom'

const ServiceCards = ({ data }) => {
    let navigate = useNavigate()
    return (
        <div className=' ' >
            {data && <main className={`relative rounded-xl overflow-hidden `} >
                <img loading='lazy' src={data.img ? data.img : require('../assest/softwareConsultingCard.png')}
                    className='w-full h-full object-cover ' alt="Service Card" />
                <div className='absolute p-3 poppins top-4 text-slate-100 min-h-[10vh] w-full ' >
                    <h2 className='text-center flex items-center gap-2 mx-auto w-fit ' >
                       <span className=' order-1 text-4xl ' >  {data.heading ? data.heading : 'Software'} </span>
                        {data.sptheading && <span className={` text-4xl ${data.splfont}`}> {data.sptheading}</span>}
                    </h2>
                    <p className='mx-auto text-center w-[85%] sm:w-[80%] lg:w-[60%]  ' >
                        {data.content ? data.content : `Customized Software Services That Meet Your Business Needs`}
                    </p>
                    <button onClick={() => navigate(`/${data.path}`)}
                        className=' mx-auto w-fit p-2 px-4 bg-blue-700 flex my-10 rounded-full text-slate-100 ' >
                        Find More
                    </button>
                </div>

            </main>}

        </div>
    )
}

export default ServiceCards