import { useState, useEffect, useRef } from "react";

const useInView = (options = { threshold: 0.1 }) => {
    const [isInView, setIsInView] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsInView(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (isInView == false)
                    setIsInView(entry.isIntersecting);
            },
            options
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [options]);
    return [ref, isInView];
};

export default useInView;
