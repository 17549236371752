import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Blogs = () => {
  const navigate = useNavigate();

  let [webblogs, setblogs] = useState([]);

  useEffect(() => {
    axios.get('https://backendapi.meridatechminds.com/api/blog/').then(r => {
      console.log('Blogs', r.data);
      setblogs(r.data);
    });
  }, []);

  const date_function = dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return { day, month, year };
  };

  return (
    <div
      className="blogs-div"
      style={{ minHeight: '100vh' }}
    >
     
      <div
        className="row m-0 container mx-auto"
        style={{ minHeight: '100vh' }}
      >
        <div className="col-lg-3 d-flex flex-column justify-content-center col-md-6 px-md-4 border-end border-start">
          <p className="strong fs-1 fw-bold">Blog</p>
          <p
            className=""
            style={{ color: '#CDCDCD' }}
          >
            Discover helpful tips & insights for your business growth.
          </p>
          <button
            className="rounded-pill px-4 py-2 fw-medium border-0"
            style={{ backgroundColor: '#FFE600' }}
            onClick={() => navigate('/blogs')}
          >
            View All
          </button>
        </div>

        {webblogs.slice(0, 3).map(x => {
          const { day, month, year } = date_function(x.created_at);
          return (
            <div className="col-lg-3 d-flex flex-column justify-content-center col-md-6 px-md-4 border-end">
              <p
                className="fs-1 fw-bold m-0"
                style={{ color: '#CCCCCC' }}
              >
                {day}
              </p>
              <p
                className=""
                style={{ fontSize: '13px' }}
              >
                {month} {year}
              </p>

              <div>
                <p className="fw-bold rubik-blog fs-5">{x.Main_Title}</p>

                <p style={{ color: '#62626F' }}>
                  {x.Paragraph1 && x.Paragraph1.slice(0, 300)}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <Footer />
    </div>
  );
};

export default Blogs;
