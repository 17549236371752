import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="footer-div py-5">
      <div className="row m-0 container mx-auto align-items-center mb-5">
        <div className="col-md-6 col-lg-2">
          <img
            src={require('../images/Merida Tech Minds_logo_Blue 2.png')}
            alt=""
          />
        </div>
        <div className="col-md-6 col-lg-3 ms-auto">
          <p className="m-0 strong fs-1 text-end">
            SAY <span className=" strong ">Hello</span>
          </p>
        </div>
      </div>

      <div className="row container m-0 mx-auto">
        <div className="col-md-6">
          <p className="m-0 rubik-blog fs-3">
            LET'S <span className="fw-bold">DISCUSS</span>
          </p>
          <div className="row m-0 mt-4">
            <div className="col-md-5 ps-md-0">
              <input
                type="text"
                placeholder="Your Name"
                className="footer-inputs form-control rounded-0 border-bottom border-0 bg-transparent border-2 border-secondary"
              />
            </div>
            <div className="col-md-5 ps-md-0 mx-md-auto mt-4 mt-lg-0 pt-3 pt-lg-0">
              <input
                type="text"
                placeholder="Your Email"
                className="footer-inputs form-control rounded-0 border-bottom border-0 bg-transparent border-2 border-secondary"
              />
            </div>
            <div className="col-md-5 ps-md-0 mt-4 pt-3">
              <input
                type="text"
                placeholder="Phone No"
                className="footer-inputs form-control rounded-0 border-bottom border-0 bg-transparent border-2 border-secondary"
              />
            </div>
            <div className="col-md-5 ps-md-0 mx-md-auto mt-4 pt-3">
              <input
                type="text"
                placeholder="Your Message"
                className="footer-inputs form-control rounded-0 border-bottom border-0 bg-transparent border-2 border-secondary"
              />
            </div>

            <div className="col-lg-4 ps-md-0 mt-4 pt-4">
              <button
                className="btn rounded-pill w-100 fw-bold"
                style={{
                  fontSize: '14px',
                  backgroundColor: '#FFE600',
                  height: '46px',
                }}
              >
                SEND
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row m-0 mt-5 mt-lg-0">
            <div className="col-lg-4">
              <p
                className="fw-medium rubik-blog"
                style={{ color: '#62626F' }}
              >
                Our Company
              </p>

              <p
                className="rubik-blog footer-links"
                onClick={() =>
                  location.pathname === '/' ? scrollToTop() : navigate('/')
                }
              >
                Home
              </p>
              <p
                className="rubik-blog footer-links"
                onClick={() => navigate('/blogs')}
              >
                Blogs
              </p>
              <p
                className="rubik-blog footer-links"
                onClick={() => navigate('/contact-us')}
              >
                Contact Us
              </p>
            </div>

            <div className="col-lg-4">
              <p
                className="fw-medium rubik-blog"
                style={{ color: '#62626F' }}
              >
                Affiliate Businesses
              </p>

              <p className="rubik-blog footer-links">Edu Tech</p>
              <p className="rubik-blog footer-links">Hr Conceiting</p>
              <p className="rubik-blog footer-links">Training</p>
              <p className="rubik-blog footer-links">SLA</p>
            </div>

            <div className="col-lg-4">
              <p
                className="fw-medium rubik-blog m-0"
                style={{ color: '#62626F' }}
              >
                Merida Tech Minds (OPC) Pvt.Ltd
              </p>

              <p
                // href="https://maps.app.goo.gl/nRUw44bJheMo9NBa7"
                className="rubik-blog footer-links mt-2"
                // rel="noreferrer"
              >
                20-2 ,Keshava Krupa Building 2nd Floor, 30th cross, 10th Main
                Rd, 4th Block, Jayanagar, Bengaluru, Karnataka 560011
              </p>
              <p className="rubik-blog footer-links mt-2">0 98765 43210</p>
              <p className="rubik-blog footer-links mt-2">
                info@meridatechminds.com
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 container mx-auto mt-5 pt-4">
        <div className="col-lg-9 d-flex align-items-center justify-content-between">
          <a
            href="/"
            className="footer-sublinks text-decoration-none"
          >
            @2024 Merida tech Minds
          </a>
          <a
            href="https://www.instagram.com/meridatechminds_/"
            target="_blank"
            className="footer-sublinks text-decoration-none"
            rel="noreferrer"
          >
            INSTAGRAM
          </a>
          <a
            href="https://www.facebook.com/MeridaTechMinds/"
            target="_blank"
            className=" text-decoration-none footer-sublinks"
            rel="noreferrer"
          >
            FACEBOOK
          </a>
          <a
            href="https://www.linkedin.com/company/merida-tech-minds/"
            target="_blank"
            className="footer-sublinks text-decoration-none"
            rel="noreferrer"
          >
            LINKEDIN
          </a>
          <a
            href="https://twitter.com/MTechminds/"
            target="_blank"
            className="footer-sublinks text-decoration-none"
            rel="noreferrer"
          >
            TWITTER
          </a>
          <a
            href="https://in.pinterest.com/meridatechminds/"
            target="_blank"
            className="footer-sublinks text-decoration-none"
            rel="noreferrer"
          >
            PINTEREST
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
