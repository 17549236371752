import React from 'react'

const WhyChooseUs = () => {
    let whyChooseContent = [
        {
            img: '../assest/expertSolution.png',
            title: 'Expert Solutions',
            content: `With years of experience, we deliver personalized services that fit your business needs and guarantee great results.`,
        },
        {
            img: '../assest/transparentCommunication.png',
            title: 'Transparent Communication',
            content: `We keep you informed throughout the process so you always know exactly what is happening with your project.`
        },
        {
            img: '../assest/customerFirst.png',
            title: 'Customer-First Approach',
            content: `Your satisfaction is our top priority. We design our solutions to ensure your success every step of the way.`
        },
        {
            img: '../assest/latestTechnology.png',
            title: 'Latest Technology',
            content: `We use the most up-to-date tools to help your projects run faster, work smarter, and achieve better results.`
        },
        {
            img: '../assest/timelydelivery.png',
            title: 'Timely Delivery',
            content: `Every project is completed on time, ensuring your deadlines are met without any delays or last-minute surprises.`
        },
        {
            img: '../assest/proventrackrecord.png',
            title: 'Proven Track Record',
            content: `With hundreds of successful projects, our work speaks for itself. We are ready to support your business next.`
        },
        {
            img: '../assest/costefficient.png',
            title: 'Cost-Effective',
            content: `Save money without cutting quality. Our services provide excellent value while fitting your budget perfectly.`
        },
        {
            img: '../assest/longterm.png',
            title: 'Long-Term Support',
            content: `Build lasting relationships with us. We are here to support you by offering help whenever you need it.`
        },
        {
            img: '../assest/collabrative.png',
            title: 'Collaborative Teamwork',
            content: `Our team works hand-in-hand with you, solving challenges together for better results and smarter solutions.`
        },
        {
            img: '../assest/riskFree.png',
            title: 'Risk-Free Planning',
            content: `We handle risks to keep your business safe, letting you concentrate on growth and achieving success.`
        },
    ]
    return (
        <div className='min-h-[50vh] py-5  ' >
            <div className='w-fit mx-auto container text-center ' >
                <h4 className='megira text-slate-700 text-opacity-10 text-5xl sm:text-6xl lg:text-8xl ' >Advantage </h4>
                <h5 className=' relative bottom-10 h-[10px] text-3xl lg:text-4xl lg:bottom-12 mb-0' >Why Choose Us</h5>
                <main className='row  mx-auto  ' >
                    {
                        whyChooseContent.map((obj) => (
                            <div className='my-3 col-md-5 col-lg-6' >
                                <div className=' rounded shadow-sm p-2 flex gap-2 ' >
                                    <img src={obj.img} alt="CardImage" className='rounded object-cover  ' />
                                    <div className='text-start ' >
                                        <h6 className=' text-xl text-slate-700 ' > {obj.title} </h6>
                                        <p className='text-sm text-slate-600 ' > {obj.content} </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </main>

            </div>
        </div>
    )
}

export default WhyChooseUs