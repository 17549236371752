import React from 'react'

const Mobile_app_layout2 = () => { 
    
  return (
    <div className='mobile-app-layout2'>
      <div className="row m-0 container mx-auto py-5">
        <p className='fs-1 fw-medium text-center mb-5'>Mobile App Development Solutions for Every Business Requirement</p>
        <div className="col-lg-4 px-lg-4 mb-5">
            <div className='border p-4 mobile-app-layout2-div' style={{borderRadius:'15px'}}>
                <img src={require('../images/innerpage4/Group 1171275169.png')} style={{width:'20%'}} alt="" />
                <p className='fs-4 fw-medium mt-4'>Custom App Development</p>
                <p className="text-secondary" style={{fontSize:'15px'}}>
                Apps built just for you, aligning perfectly with unique business needs and goals.
                </p>
            </div>
        </div>
        <div className="col-lg-4 px-lg-4 mb-5">
            <div className='border p-4 mobile-app-layout2-div' style={{borderRadius:'15px'}}>
                <img src={require('../images/innerpage4/Group 1171275170.png')} style={{width:'20%'}} alt="" />
                <p className='fs-4 fw-medium mt-4'>Mobile UI/UX Design</p>
                <p className="text-secondary" style={{fontSize:'15px'}}>
                Our user-friendly designs ensure easy navigation, making every interaction enjoyable.
                </p>
            </div>
        </div>
        <div className="col-lg-4 px-lg-4 mb-5">
            <div className='border p-4 mobile-app-layout2-div' style={{borderRadius:'15px'}}>
                <img src={require('../images/innerpage4/Group 1171275171.png')} style={{width:'20%'}} alt="" />
                <p className='fs-4 fw-medium mt-4'>Embedded IoT App Development</p>
                <p className="text-secondary" style={{fontSize:'15px'}}>
                Create smart apps that connect devices, simplifying tasks and improving everyday experiences.
                </p>
            </div>
        </div>

        <div className="col-lg-4 px-lg-4 mb-5">
            <div className='border p-4 mobile-app-layout2-div' style={{borderRadius:'15px'}}>
                <img src={require('../images/innerpage4/Group 1171275169.png')} style={{width:'20%'}} alt="" />
                <p className='fs-4 fw-medium mt-4'>E-commerce App Development</p>
                <p className="text-secondary" style={{fontSize:'15px'}}>
                Build apps that simplify online shopping, making it fun and engaging for users.
                </p>
            </div>
        </div>
        <div className="col-lg-4 px-lg-4 mb-5">
            <div className='border p-4 mobile-app-layout2-div' style={{borderRadius:'15px'}}>
                <img src={require('../images/innerpage4/Group 1171275170.png')} style={{width:'20%'}} alt="" />
                <p className='fs-4 fw-medium mt-4'>Enterprise App Solutions</p>
                <p className="text-secondary" style={{fontSize:'15px'}}>
                Develop secure and scalable apps that support complex business operations smoothly.
                </p>
            </div>
        </div>
        <div className="col-lg-4 px-lg-4 mb-5">
            <div className='border p-4 mobile-app-layout2-div' style={{borderRadius:'15px'}}>
                <img src={require('../images/innerpage4/Group 1171275171.png')} style={{width:'20%'}} alt="" />
                <p className='fs-4 fw-medium mt-4'>App Support and Maintenance</p>
                <p className="text-secondary" style={{fontSize:'15px'}}>
                Keep apps updated and running well with reliable support and maintenance services.
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Mobile_app_layout2
