import React from 'react'

const HowWeWorks = () => {
    let worksContent = [
        {
            img: '../assest/connectionWithYou.png',
            content: `we start by understanding your needs, listening closely to your ideas, and gathering all the details to serve you better.`,
            title: 'Connecting with you  '
        },
        {
            img: '../assest/planning.png',
            content: `Finally, we put into action, keeping you updated and delivering results that make you happy and satisfied`,
            title: 'Planning'
        },
        {
            img: '../assest/execution.png',
            content: `Next, we create a custom plan just for you, outlining each step to meet your goals and expectations effectively and efficiently`,
            title: 'Execution'
        },
        {
            img: '../assest/CustomizePlanning.png',
            content: `Next, we develop a clear plan that outlines the steps we’ll take, ensuring it meets your goals and expectations.`,
            title: 'Customize Planning'
        },
        {
            img: '../assest/initialConsulting.png',
            title: `Initial Consulting`,
            content: 'We begin by connecting with you, listening to your ideas, and understanding your specific requirements to ensure we’re on the same page.'
        },
        {
            img: '../assest/executeWithCare.png',
            content: `Then, we put our plan into action, executing each detail carefully while keeping you updated on how things are going throughout the process.`,
            title: 'Execute With Care'
        },
    ]
    return (
        <div className='py-5 lg:min-h-[80vh] bg-[#2A2A2A] ' >
            <main className='container mx-auto text-slate-100 poppins ' >
                <div className='w-fit mx-auto text-center ' >
                    <h4 className='megira text-[#ffffff] text-opacity-30 text-5xl sm:text-6xl lg:text-8xl ' >Process </h4>
                    <h5 className=' relative bottom-10 h-[10px] text-3xl lg:text-4xl lg:bottom-12 mb-0' >How We Work </h5>
                    <p className='w-[90%] lg:w-[75%] mx-auto ' >
                        The phrase "how we work content" can refer to creating or organizing content that explains how a team, business, or organization works. This type of content is often used in websites,
                        proposals, or presentations to clarify processes, highlight values, and communicate effectively with audiences.
                    </p>
                </div>
                <section className=' row my-5 ' >
                    {
                        worksContent.map((obj) => (
                            <div className=' col-md-6  text-center poppins col-lg-4 p-2 ' >
                                <div className='bg-white rounded h-full p-3 text-slate-700 ' >
                                    <img src={obj.img} alt="Card Image" className=' my-3  mx-auto ' />
                                    <h6 className='text-lg sm:text-xl ' > {obj.title} </h6>
                                    <p className='text-sm ' >
                                        {obj.content}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </section>
            </main>
        </div>
    )
}

export default HowWeWorks