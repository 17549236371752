import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon_div from './Icon_div';

const Mtm_offcanvas = () => {
  const navigate = useNavigate();
  const [state, setstate] = useState(false);

  return (
    <div>
      <div
        class="offcanvas offcanvas-end"
        style={{ transition: '0.7s' }}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabindex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div class="offcanvas-header">
          <img
            src={require('../images/Merida Tech Minds_logo_Blue 2.png')}
            style={{ width: '30%', cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
            alt=""
          />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <p
            data-bs-dismiss="offcanvas"
            className="d-flex align-items-center justify-content-between mb-5"
            onClick={() => {
              navigate('/');
            }}
            style={{ cursor: 'pointer' }}
          >
            <span>Home</span>
            
          </p>

          <p
            data-bs-dismiss="offcanvas"
            className="d-flex align-items-center justify-content-between mb-5"
            onClick={() => {
              setstate(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <span>Services</span>
            
          </p>

          <p
            data-bs-dismiss="offcanvas"
            onClick={() => {
              navigate('/blogs');
            }}
            className="d-flex align-items-center justify-content-between mb-5"
            style={{ cursor: 'pointer' }}
          >
            <span>Blogs</span>
            
          </p>

          <p
            data-bs-dismiss="offcanvas"
            onClick={() => {
              navigate('/contact-us');
            }}
            className="d-flex align-items-center justify-content-between mb-5"
            style={{ cursor: 'pointer' }}
          >
            <span>Contact Us</span>
            
          </p>

          <p
            data-bs-dismiss="offcanvas"
            className="d-flex align-items-center justify-content-between mb-5"
            style={{ cursor: 'pointer' }}
          >
            <span>Portfolio</span>
            
          </p>
        </div>
      </div>

      <Icon_div
        state={state}
        setstate={setstate}
      />
    </div>
  );
};

export default Mtm_offcanvas;
